<template>
  <div class="register-wrap">
    <!-- Before you apply -->
    <div class="before-you-apply container">
      <public-title :title="registerData.title" />
      <div class="apply-list">
        <div v-for="(item, index) in registerData.description" :key="index" class="apply-item">
          <div class="img-box">
            <!-- <svg class="icon" aria-hidden="true">
              <use :xlink:href="item.image" />
            </svg> -->
            <img :src="item.image" alt="" />
          </div>
          <p class="text-box" v-html="item.description"></p>
        </div>
      </div>
      <div class="others-box">
        <p class="others-text">{{ registerData.learn_something }}</p>
        <ul class="others-list">
          <li v-for="(item, index) in registerData.find_partner" :key="index" class="others-item">
            <a class="link" :href="item.link" target="_blank">
              <span class="name">{{ item.name }}</span>
              <span class="iconfont icon-a-jiantouwhite1x"></span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- Be A Partner Steps -->
    <div class="steps-box">
      <div class="container">
        <public-title :title="registerData.partner_steps" />
        <ul class="steps-list">
          <li v-for="(item, index) in registerData.partner_steps_description" :key="index" class="steps-item">
            <div class="serial-number">
              <span class="number">{{ index + 1 }}</span>
            </div>
            <span class="name">{{ item.name }}</span>
          </li>
        </ul>
      </div>
    </div>
    <!-- Select Partner Type -->
    <div class="type-box">
      <div class="container">
        <public-title :title="registerData.partner_type_title" />
        <ul class="type-list">
          <li v-for="(item, index) in registerData.partner_type_description" :key="index" class="type-item">
            <div class="img-box" @click="handleForm(index)">
              <div class="img-bg" :style="`background: url(${item.image}) center center / cover no-repeat`"></div>
            </div>
            <div class="text-box">
              <p class="title-box ellipsis" @click="handleForm(index)">{{ item.title }}</p>
              <p class="desc-box ellipsis">
                <router-link :to="item.url" class="desc-link">
                  {{ item.content }}
                </router-link>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import PublicTitle from '@/components/public/PublicTitle.vue'
import { registerApi } from '@/api/register'
export default {
  components: {
    PublicTitle
  },
  data() {
    return {
      registerData: {}
    }
  },

  mounted() {
    this.getRegisterData()
  },
  methods: {
    // 获取注册页数据
    getRegisterData() {
      registerApi().then((res) => {
        this.registerData = res.data
      })
    },
    // 跳转到表单页
    handleForm(i) {
      const formUrl = [
        '/Eco',
        '/Dhop',
        '/Synergy'
      ]
      this.$router.push({ path: '/register' + formUrl[i] })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.register-wrap {
  .before-you-apply {
    padding: 96px 0 72px 0;
    .apply-list {
      display: flex;
      justify-content: space-around;
      margin-top: 68px;
      .apply-item {
        max-width: 338px;
        text-align: center;
        .img-box {
          img {
            width: 80px;
            height: 80px;
          }
        }
        .text-box {
          font-size: 16px;
          color: #303133;
          line-height: 21px;
          margin-top: 24px;
        }
      }
    }
    .others-box {
      margin-top: 48px;
      .others-text {
        font-size: 18px;
        color: #303133;
        line-height: 24px;
        text-align: center;
      }
      .others-list {
        display: flex;
        margin-top: 24px;
        .others-item {
          width: calc(25% - 12px);
          margin-right: 16px;
          border: 1px solid #E6E6E6;
          .transition();
          &:nth-child(4n) {
            margin-right: 0;
          }
          &:hover {
            background: @theme;
            .link {
              .name,
              .iconfont {
                color: #fff;
              }
            }
          }
          .link {
            display: block;
            padding: 14px 16px;
            display: flex;
            justify-content: space-around;
            .name {
              font-size: 14px;
              color: #303133;
              line-height: 19px;
            }
          }
        }
      }
    }
  }
  .steps-box {
    background: #F4F4F5;
    padding: 72px 0;
    .steps-list {
      display: flex;
      justify-content: center;
      margin-top: 8px;
      .steps-item {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .serial-number {
          width: 48px;
          height: 48px;
          background: @theme;
          border-radius: 50%;
          text-align: center;
          flex-shrink: 0;
          .number {
            font-size: 18px;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 48px;
          }
        }
        .name {
          font-size: 24px;
          font-weight: bold;
          color: #303133;
          line-height: 32px;
          margin-left: 16px;
        }
        & + .steps-item {
          margin-left: 108px;
          .name {
            &::before {
              display: block;
              width: 60px;
              height: 1px;
              content: '';
              background: #979797;
              position: absolute;
              left: -80px;
              top: 25px;
            }
          }
        }
      }
    }
  }
  .type-box {
    padding: 70px 0;
    .type-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 50px;
      .type-item {
        width: calc(33.333333% - 11px);
        box-shadow: 0px 6px 18px 0px rgba(38, 38, 38, 0.14);
        margin-right: 16px;
        margin: 0 16px 16px 0;
        text-align: center;
        align-items: center;
        .transition();
        &:nth-child(3n) {
          margin-right: 0;
        }
        .img-box {
          cursor: pointer;
          .img-bg {
            height: 280px;
          }
        }
        .text-box {
          padding: 12px 16px 24px 16px;
          .title-box {
            cursor: pointer;
            font-size: 36px;
            font-weight: 600;
            color: #232931;
            line-height: 48px;
          }
          .desc-box {
            cursor: pointer;
            margin-top: 16px;
            .desc-link {
              font-size: 16px;
              font-style: italic;
              color: #606266;
              line-height: 21px;
              display: inline-block;
              width: 100%;
              height: 100%;
            }
            &:hover {
              .desc-link {
                text-decoration: underline;
              }
            }
          }
        }
        &:hover {
          transform: translateY(-24px);
          border: 1px solid #0E5FAC;
          .text-box {
            .title-box {
              color: #0E5FAC;
            }
          }
        }
        &:hover {
          &:first-child {
            border: 1px solid @theme;
            .text-box {
              .title-box {
                color: @theme;
              }
            }
          }
          &:last-child {
            border: 1px solid #C8161D;
            .text-box {
              .title-box {
                color: #C8161D;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1366px) {
    .steps-box {
      .steps-list {
        .steps-item {
          .name {
            font-size: 20px;
            line-height: 28px;
            margin-left: 8px;
          }
          & + .steps-item {
            margin-left: 90px;
          }
        }
      }
    }
    .type-box {
      .type-list {
        .type-item {
          .img-box {
            .img-bg {
              height: 214px;
            }
          }
          .text-box {
            .title-box {
              font-size: 28px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .before-you-apply {
      padding: 48px 0 32px 0;
    }
    .steps-box {
      .steps-list {
        .steps-item {
          .serial-number {
            width: 40px;
            height: 40px;
            .number {
              font-size: 16px;
              line-height: 40px;
            }
          }
          .name {
            font-size: 14px;
            line-height: 22px;
          }
          & + .steps-item {
            margin-left: 68px;
            .name {
              &::before {
                width: 40px;
                left: -54px;
                top: 20px;
              }
            }
          }
        }
      }
    }
    .type-box {
      .type-list {
        .type-item {
          .img-box {
            .img-bg {
              height: 160px;
            }
          }
          .text-box {
            .title-box {
              font-size: 20px;
              line-height: 28px;
            }
          }
          .desc-box {
            font-size: 14px;
            margin-top: 12px;
          }
        }
      }
    }
  }
	@media screen and (max-width: 768px) {
		.before-you-apply {
      padding: 24px 0 14px 0;
			.apply-list{
				flex-direction: column;
				margin-top: 0;
				.apply-item{
					max-width: 100%;
					.text-box{
						margin-top: 16px;
						margin-bottom: 24px;
						font-size: 14px;
						line-height: 19px;
					}
				}
			}
			.others-box{
				margin-top: 0;
				.others-list{
					margin-top: 20px;
					flex-direction: column;
					align-items: center;
					.others-item{
						margin-right: 0;
						width: calc(100% - 28px);
						margin-bottom: 10px;
						.link{
							padding: 14px 24px;
							justify-content: space-between;
						}
					}
				}
			}
    }
		.steps-box{
			padding: 24px 0;
			.steps-list{
				flex-wrap: wrap;
				justify-content: flex-start;
				.steps-item{
					padding-bottom: 10px;
					.serial-number{
						width: 28px;
						height: 28px;
						.number{
							line-height: 28px;
						}
					}
					.name{
						font-size: 15px;
						line-height: 20px;
					}
					& + .steps-item {
						.name {
							&::before {
								top: 14px;
							}
						}
					}
				}
			}
		}
		.type-box{
			padding: 24px 0 48px;
			.type-list{
				flex-direction: column;
				.type-item{
					width: 100%;
					margin: 0 0 12px 0;
					display: flex;
					.img-box{
						width: 50%;
						.img-bg{
							height: 104px;
						}
					}
					.text-box{
						width: 50%;
						text-align: left;
						.title-box{
							font-size: 16px;
							line-height: 21px;
						}
						.desc-box{
							font-size: 12px;
							line-height: 16px;
							margin-top: 20px;
							font-style: italic;
						}
					}
				}
			}
		}
	}
}
</style>
<style lang="less">
.register-wrap {
  .public-title {
    .title {
      font-size: 36px;
      line-height: 48px;
    }
  }
  @media screen and (max-width: 1024px) {
    .public-title {
      .title {
        font-size: 28px;
        line-height: 48px;
      }
    }
  }
	@media screen and (max-width: 768px) {
    .public-title {
      .title {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
}
</style>

<template>
  <div class="dhop-wrap public-register-form">
    <div class="register-form-title">
      <div class="register-form-back container">
        <router-link to="/register">
          <i class="iconfont icon-a-zuojiantou1x back-icon"></i>{{ LP.lang_back_to_partner }}
        </router-link>
      </div>
      <public-title
        :title="LP.lang_dhop_form_title"
        :subtitle="LP.lang_other_partner_log_in"
      />
    </div>
    <!-- 上传文件 -->
    <div class="register-form container">
      <div class="dhop-tip-box">
        <p class="tip-text">{{ LP.lang_dipp_regrstrtion_desc }}</p>
        <p class="tip-text agreement">
          {{ LP.lang_dhop_agreement_name }}
          <a
            class="link"
            href="https://dahuatest.s3.ap-southeast-1.amazonaws.com/uploads%2Fsoft%2F20220409%2FCooperation-Agreement%28DHOP%29-english-clean1.pdf"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >{{ LP.lang_dhop_agreement_file_name }}</a>
        </p>
        <div v-loading="fileLoading" class="input-box">
          <el-upload
            ref="upload"
            class="upload-demo dhop-upload"
            :action="uploadUrl"
            :on-success="handleSuccess"
            :on-error="handleError"
            :on-remove="handleRemove"
            :file-list="fileList"
            :limit="1"
            :before-upload="handleBeforeUpload"
          >
            <el-button slot="trigger" class="gray-btn" type="primary">{{ LP.lang_upload }}</el-button>
          </el-upload>
        </div>
        <div v-if="showErrorTip" class="error-tip">
          {{ LP.lang_dipp_regrstrtion_desc }}
        </div>
      </div>
      <!-- Contact Information -->
      <div class="contact-info">
        <div class="contact-info-title">
          <h4 class="title">{{ LP.lang_contact_information }}</h4>
          <div class="line"></div>
        </div>
        <el-form
          ref="ruleFormContact"
          :model="ruleFormContact"
          :label-position="labelPosition"
          class="register-ruleForm"
        >
          <el-form-item
            v-for="(item, index) in contactData"
            :key="index"
            v-bind="item"
            :class="{'login-hidden': isLogin && (item.prop === 'password' || item.prop === 'pwConfirmation' || item.prop === 'code')}"
          >
            <el-input v-if="item.prop === 'password'" v-model="ruleFormContact[item.prop]" :class="'CI' + index" :type="pwdType1">
              <span slot="suffix" class="el-input__suffix" @click="showPwd1">
                <span class="el-input__suffix-inner">
                  <i class="el-icon-view"></i>
                </span>
              </span>
            </el-input>
            <el-input v-else-if="item.prop === 'pwConfirmation'" v-model="ruleFormContact[item.prop]" :class="'CI' + index" :type="pwdType2">
              <span slot="suffix" class="el-input__suffix" @click="showPwd2">
                <span class="el-input__suffix-inner">
                  <i class="el-icon-view"></i>
                </span>
              </span>
            </el-input>
            <el-input v-else-if="item.prop === 'email'" v-model="ruleFormContact[item.prop]" :disabled="isLogin" :class="['CI' + index , 'email-input']">
              <div slot="suffix" class="el-input__suffix">
                <el-button v-if="!isSuccessVerify" class="verify-btn el-input__suffix-inner" @click="openVerifyPopover()">{{ LP.lang_verify }}</el-button>
                <el-button
                  v-else
                  class="email-time-btn el-input__suffix-inner"
                  type="primary"
                >
                  <span>{{ count }}s</span>
                </el-button>
              </div>
            </el-input>
            <el-input v-else v-model="ruleFormContact[item.prop]" :class="'CI' + index" :disabled="isLogin" />
            <span v-if="item.type === 'password' && !isLogin" class="check-tips">
              {{ LP.lang_strong_password }}
            </span>
          </el-form-item>
        </el-form>
      </div>
      <!-- Company Information -->
      <div class="company-info">
        <div class="contact-info-title">
          <h4 class="title">{{ LP.lang_company_information }}</h4>
          <div class="line"></div>
        </div>
        <el-form
          ref="ruleFormCompany"
          :model="ruleFormCompany"
          :label-position="labelPosition"
          class="register-ruleForm"
        >
          <el-form-item
            v-for="(item, index) in companyData"
            :key="index"
            v-bind="item"
            :class="{
              'width-large': item._type === 'textarea' || item._type === 'checkbox' || item._type === 'radio',
              'label-before': item._labelType === 'slot'
            }"
          >
            <!-- 自定义label内容 -->
            <template v-if="item._labelType === 'slot'" #label>
              <span :class="'PI' + index" v-html="item._labelContent"></span>
            </template>
            <!-- input -->
            <el-input v-if="item._type === 'input'" v-model="ruleFormCompany[item.prop]" :class="'PI' + index" :disabled="isLogin" />
            <!-- textarea -->
            <el-input v-if="item._type === 'textarea'" v-model="ruleFormCompany[item.prop]" :class="'PI' + index" type="textarea" :disabled="isLogin" />
            <!-- select -->
            <el-select v-if="item._type === 'select'" v-model="ruleFormCompany[item.prop]" :class="'PI' + index" placeholder="" :disabled="isLogin" filterable @change="getProvince($event, item.prop)">
              <el-option
                v-for="(itemChild, indexIndex) in item.list"
                :key="indexIndex"
                :label="itemChild.label"
                :value="itemChild.label"
              >
                <!-- item.prop === 'country' || item.prop === 'province' || item.prop === 'city' ? itemChild.value :  -->
                {{ itemChild.label }}
              </el-option>
            </el-select>
            <!-- select multiple -->
            <el-select v-if="item._type === 'selectMultiple'" v-model="ruleFormCompany[item.prop]" :class="'PI' + index" :disabled="isLogin" multiple placeholder="">
              <el-option v-for="(itemChild, indexIndex) in item.list" :key="indexIndex" :label="itemChild.label" :value="itemChild.label">
                {{ itemChild.label }}
              </el-option>
            </el-select>
            <!-- checkbox -->
            <el-checkbox-group v-if="item._type === 'checkbox'" v-model="ruleFormCompany[item.prop]" :disabled="isLogin" :class="'PI' + index">
              <public-check-box
                v-for="(itemChild, indexIndex) in industriesList"
                :key="indexIndex"
                :label="itemChild.label"
              >{{ itemChild.label }}
              </public-check-box>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
      <!-- App Information -->
      <div class="app-infomation">
        <div class="contact-info-title">
          <h4 class="title">{{ LP.lang_app_informatiom }}</h4>
          <div class="line"></div>
        </div>
        <el-form
          ref="ruleFormApp"
          :model="ruleFormApp"
          :label-position="labelPosition"
          class="register-ruleForm"
        >
          <el-form-item
            v-for="(item, index) in appData"
            :key="index"
            v-bind="item"
            :class="{
              'width-large': item._type === 'radio' || item._type === 'checkbox' || item._type === 'textarea',
              'large-checkbox': item._largeCheckbox
            }"
          >
            <!-- 自定义label内容 -->
            <template v-if="item._labelType === 'slot'" #label>
              <span :class="'AI' + index" v-html="item.label + item._labelContent"></span>
            </template>
            <!-- 单选 -->
            <el-radio-group v-if="item._type === 'radio'" v-model="ruleFormApp[item.prop]" :class="'AI' + index">
              <el-radio
                v-for="(itemChild, indexChild) in item.childList"
                :key="indexChild"
                :label="itemChild.label"
              >
                <template v-if="itemChild._labelType === 'slot'">
                  <span>{{ itemChild.label }}</span>
                  <el-input
                    v-model="ruleFormApp[itemChild._inputProp]"
                    clearable
                    class="label-input"
                  />
                </template>
              </el-radio>
            </el-radio-group>
            <!-- 多选 -->
            <el-checkbox-group v-if="item._type === 'checkbox'" v-model="ruleFormApp[item.prop]" :class="'AI' + index">
              <el-checkbox
                v-for="(itemChild, indexChild) in item.childList"
                :key="indexChild"
                :label="itemChild.label"
                name="type"
              >
                <template v-if="itemChild._labelType === 'slot'">
                  <span>{{ itemChild.label }}</span>
                  <el-input
                    v-model="ruleFormApp[itemChild._inputProp]"
                    clearable
                    class="label-input"
                  />
                </template>
              </el-checkbox>
            </el-checkbox-group>
            <!-- 文本框 -->
            <el-input v-if="item._type === 'textarea'" v-model="ruleFormApp[item.prop]" :class="'AI' + index" type="textarea" />
          </el-form-item>
        </el-form>
      </div>
      <div class="public-register-submit">
        <el-button
          v-if="isLogin"
          class="submit-btn"
          type="primary"
          @click="accountUpgradeSubmit()"
        >
          {{ LP.lang_submit }}
        </el-button>
        <el-button
          v-else
          class="submit-btn"
          type="primary"
          @click="handleSubmit()"
        >
          {{ LP.lang_submit }}
        </el-button>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      class="dialog"
      :before-close="handleClose"
    >
      <div class="content">
        Thank you for the registration. Your application is now being reviewed by the Administrator and will give you feedback in 2 days. We appreciate your patience.
      </div>
    </el-dialog>
    <!-- 邮箱验证弹窗 -->
    <slide-dialog
      :email-dialog-visible="emailDialogVisible"
      :email-error-dialog-visible="emailErrorDialogVisible"
      :email-error-text="emailErrorText"
      @emailSuceess="emailSuceess"
      @closeEmailError="closeEmailError"
      @closeDialog="closeDialog"
    />
  </div>
</template>

<script>
import PublicTitle from '@/components/public/PublicTitle'
import PublicCheckBox from '@/components/public/PublicCheckBox.vue'
import { registerFormApi, provinceCityApi, accountUpgradeApi } from '@/api/register'
import { countrySelectApi } from '@/api/solutions'
import { _axios } from '@/plugins/axios.js'
import { getUserInfoApi, emailVerifyApi } from '@/api/login'
import { JSEncrypt } from 'jsencrypt'
import SlideDialog from '@/components/public/SlideDialog.vue'
export default {
  components: {
    PublicTitle,
    PublicCheckBox,
    SlideDialog
  },
  data() {
    // Email Confirmation
    // var _confirmEmail = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('Please enter the email address again'))
    //   } else if (value !== this.ruleFormContact.email) {
    //     callback(new Error('The two input email addresses are inconsistent!'))
    //   } else {
    //     callback()
    //   }
    // }
    // Password
    var _isPassword = (rule, value, callback) => {
      // 必须包含8-32位字符（大小写英文/数字/符号）三种组合
      const passWordReg = /(?=.*[0-9])(?=.*[a-zA-Z]).{8,32}/
      if (value === '') {
        return callback(new Error('Please enter your password'))
      } else {
        if (passWordReg.test(value)) {
          callback()
        } else {
          callback(new Error('Please enter the correct passWord'))
        }
      }
    }
    // Password Confirmation
    var _confirmPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter your password again'))
      } else if (value !== this.ruleFormContact.password) {
        callback(new Error('The two input passwords do not match!'))
      } else {
        callback()
      }
    }
    return {
      isLogin: false, // 进入注册页时判断是否登录
      ruleFormContactErrs: {
        firstName: 'CI/0',
        lastName: 'CI/1',
        email: 'CI/3',
        code: 'CI/4',
        password: 'CI/6',
        pwConfirmation: 'CI/7'
      }, // ruleFormContact错误信息对照表
      ruleFormCompanyErrs: {
        companyName: 'PI/0',
        companyUrl: 'PI/1',
        country: 'PI/5',
        companyDescription: 'PI/6',
        employees: 'PI/7',
        businessAreas: 'PI/8',
        companyIndustries: 'PI/9',
        productsInFocus: 'PI/10'
      }, // ruleFormCompany错误信息对照表
      ruleFormAppErrs: {
        framework1: 'AI/0',
        framework2: 'AI/1',
        framework3: 'AI/2',
        framework4: 'AI/3',
        framework5: 'AI/4',
        framework6: 'AI/5',
        framework7: 'AI/6',
        framework8: 'AI/7'
      }, // ruleFormApp错误信息对照表
      pwdType1: 'password', // 切换密码框明文
      pwdType2: 'password', // 切换确认密码框明文
      labelPosition: 'top',
      dhopInput: '', // 搜索框
      fileList: [], // 上传文件列表
      file: '', // 上传文件名
      fileLoading: false, // 上传文件loading
      uploadUrl: _axios.defaults.baseURL + '/home/upload',
      // Contact Information
      ruleFormContact: {
        firstName: '',
        lastName: '',
        position: '',
        email: '',
        code: '',
        phoneNumber: '',
        password: '',
        pwConfirmation: ''
      },
      contactData: [
        {
          label: 'First Name', // 显示名
          prop: 'firstName', // rule校验字段
          rules: [
            { required: true, message: 'Please enter first name', trigger: 'blur' }
          ]
        },
        {
          label: 'Last Name',
          prop: 'lastName',
          rules: [
            { required: true, message: 'Please enter last name', trigger: 'blur' }
          ]
        },
        {
          label: 'Position/Title',
          prop: 'position'
        },
        {
          label: 'Email Address',
          prop: 'email',
          rules: [
            { required: true, message: 'Please enter the email address', trigger: 'blur' },
            { type: 'email', message: 'Please enter the correct email address', trigger: ['blur', 'change'] }
          ]
        },
        {
          label: 'Code',
          prop: 'code',
          rules: [
            { required: true, trigger: 'blur' }
          ]
        },
        {
          label: 'Phone Number',
          prop: 'phoneNumber'
        },
        {
          label: 'Password',
          prop: 'password',
          rules: [
            { required: true, validator: _isPassword, trigger: 'blur' }
          ],
          type: 'password'
        },
        {
          label: 'Password Confirmation',
          prop: 'pwConfirmation',
          rules: [
            { required: true, validator: _confirmPassword, trigger: 'blur' }
          ],
          type: 'password'
        }
      ],
      // Company Information
      ruleFormCompany: {
        companyName: '',
        companyUrl: '',
        postalCode: '',
        city: '',
        province: '',
        country: '',
        countryCode: '',
        provinceCode: '',
        cityCode: '',
        companyDescription: '',
        employees: '',
        businessAreas: [],
        companyIndustries: [],
        productsInFocus: ''
      },
      companyData: [
        {
          label: 'Company Name', // 显示名
          prop: 'companyName', // rule校验字段
          rules: [
            { required: true, message: 'Please enter company name', trigger: 'blur' }
          ],
          _type: 'input' // type类型
        },
        {
          label: 'Company URL',
          prop: 'companyUrl',
          rules: [
            { required: true, message: 'Please enter company URL', trigger: 'blur' }
          ],
          _type: 'input'
        },
        {
          label: 'Zip/Postal Code',
          prop: 'postalCode',
          _type: 'input'
        },
        {
          label: 'Country/Region',
          prop: 'country',
          rules: [
            { required: true, message: 'Please select country/region', trigger: 'blur' }
          ],
          _type: 'select',
          list: []
        },
        {
          label: 'State/Province',
          prop: 'province',
          rules: [
            { required: true, message: 'Please select State/Province', trigger: 'blur' }
          ],
          _type: 'select',
          list: []
        },
        {
          label: 'City/Town',
          prop: 'city',
          rules: [
            { required: true, message: 'Please select City/Town', trigger: 'blur' }
          ],
          _type: 'select',
          list: []
        },
        {
          label: 'The Project Description* (brief overview of your company and your products/services)',
          prop: 'companyDescription',
          rules: [
            { required: true, message: 'Please enter your company description', trigger: 'blur' }
          ],
          _type: 'textarea',
          _labelType: 'slot',
          _labelContent: '<span class="label-before">The Project Description</span><span class="label-content">(brief overview of your company and your products/services)</span>'
        },
        {
          label: 'No. of Employees',
          prop: 'employees',
          rules: [
            { required: true, message: 'Please select No. of employees', trigger: 'blur' }
          ],
          _type: 'select',
          list: [
            { label: '1~10', value: 1 },
            { label: '10~100', value: 2 },
            { label: '100~1000', value: 3 },
            { label: '>1000', value: 4 }
          ]
        },
        {
          label: 'Main Business Areas',
          prop: 'businessAreas',
          rules: [
            { required: true, message: 'Please enter main areas of your company', trigger: 'blur' }
          ],
          _type: 'selectMultiple',
          list: [
            { label: 'Africa', value: '1' },
            { label: 'Asia', value: '2' },
            { label: 'Australia&New Zealand', value: '3' },
            { label: 'CIS region', value: '4' },
            { label: 'Western Europe', value: '5' },
            { label: 'CEE&Nordic Region', value: '6' },
            { label: 'India', value: '7' },
            { label: 'Latin America', value: '8' },
            { label: 'Middle East&North Africa', value: '9' },
            { label: 'North America', value: '10' },
            { label: 'UK & Ireland', value: '11' }
          ]
        },
        {
          label: 'Main Industries of Your Company',
          prop: 'companyIndustries',
          rules: [
            { type: 'array', required: true, message: 'Please enter main industries of your company', trigger: 'change' }
          ],
          _type: 'checkbox'
        },
        {
          label: 'Which Dahua Products are Firmly in Focus?',
          prop: 'productsInFocus',
          rules: [
            { required: true, message: 'Please enter which Dahua products are firmly in focus', trigger: 'change' }
          ],
          _type: 'textarea'
        }
      ],
      industriesList: [ // Main Industries of Your Company
        {
          label: 'Retail'
        },
        {
          label: 'Banking & Finance'
        },
        {
          label: 'Transportation'
        },
        {
          label: 'Commercial Buiding'
        },
        {
          label: 'Public order & safety'
        },
        {
          label: 'Critical infrastructure'
        },
        {
          label: 'Manufacturing & Production'
        }
      ],
      ruleFormApp: { // App Informatiom
        framework1: '',
        framework2: [],
        framework3: '',
        framework4: '',
        framework5: '',
        framework6: [],
        framework7: [],
        framework8: [],
        app_description: '',
        frameworkOthers1: '',
        frameworkOthers6: '',
        frameworkOthers8: ''
      },
      appData: [ // App Informatiom
        {
          label: 'If app implements AI intelligent business, which network framework do you use?',
          prop: 'framework1',
          rules: [
            { required: true, message: 'Required fields cannot be blank!', trigger: 'change' }
          ],
          childList: [
            {
              label: 'Caffe'
            },
            {
              label: 'Tensorflow'
            },
            {
              label: 'Pytorchr'
            },
            {
              label: 'Others',
              _labelType: 'slot',
              _inputProp: 'frameworkOthers1'
            }
          ],
          _type: 'radio'
        },
        {
          label: 'Which Dahua products do you need to develop app on?',
          prop: 'framework2',
          rules: [
            { required: true, message: 'Required fields cannot be blank!', trigger: 'change' }
          ],
          childList: [
            {
              label: 'Network Camera'
            },
            {
              label: 'Thermal Camera'
            },
            {
              label: 'Network Recorder'
            },
            {
              label: 'PTZ Camera'
            },
            {
              label: 'Traffic Camera'
            }
          ],
          _type: 'checkbox',
          _labelType: 'slot',
          _labelContent: '<span class="dhop-label-content">(Multiple options)</span>'
        },
        {
          label: 'How much flash / EMMC storage do you need?',
          prop: 'framework3',
          rules: [
            { required: true, message: 'Required fields cannot be blank!', trigger: 'change' }
          ],
          childList: [
            {
              label: '256M'
            },
            {
              label: '512M'
            },
            {
              label: '1G'
            },
            {
              label: '2G and above'
            }
          ],
          _type: 'radio'
        },
        {
          label: 'How much memory do you need?',
          prop: 'framework4',
          rules: [
            { required: true, message: 'Required fields cannot be blank!', trigger: 'change' }
          ],
          childList: [
            {
              label: '64M'
            },
            {
              label: '128M'
            },
            {
              label: '256M'
            },
            {
              label: '1G'
            },
            {
              label: '2G and above'
            }
          ],
          _type: 'radio'
        },
        {
          label: 'How much AI computing power do you need?',
          prop: 'framework5',
          rules: [
            { required: true, message: 'Required fields cannot be blank!', trigger: 'change' }
          ],
          childList: [
            {
              label: '1T'
            },
            {
              label: '2T'
            },
            {
              label: '4T'
            }
          ],
          _type: 'radio'
        },
        {
          label: 'Which of the following data need to obtain in app',
          prop: 'framework6',
          rules: [
            { required: true, message: 'Required fields cannot be blank!', trigger: 'change' }
          ],
          childList: [
            {
              label: 'PCM'
            },
            {
              label: 'YUV'
            },
            {
              label: 'Video Stream'
            },
            {
              label: 'Audio Stream'
            },
            {
              label: 'Others',
              _labelType: 'slot',
              _inputProp: 'frameworkOthers6'
            }
          ],
          _type: 'checkbox',
          _labelType: 'slot',
          _labelContent: '<span class="dhop-label-content">(Multiple options)</span>'
        },
        {
          label: 'What encoding and decoding functions need to be used in app',
          prop: 'framework7',
          rules: [
            { required: true, message: 'Required fields cannot be blank!', trigger: 'change' }
          ],
          childList: [
            {
              label: 'YUV encoding to JPEG'
            },
            {
              label: 'H.264 H.265 decoding to YUV'
            },
            {
              label: 'JPEG decoding to YUV'
            }
          ],
          _type: 'checkbox',
          _labelType: 'slot',
          _labelContent: '<span class="dhop-label-content">(Multiple options)</span>'
        },
        {
          label: 'How does app want to process the results',
          prop: 'framework8',
          rules: [
            { required: true, message: 'Required fields cannot be blank!', trigger: 'change' }
          ],
          childList: [
            {
              label: 'The result are transmittef to the private platform through socket communication'
            },
            {
              label: 'Private platform integrates Dahua netsdk and receives the results through netsdk'
            },
            {
              label: 'The results format follows the Dahua protocol data format, and uses Dahua products to receive the results'
            },
            {
              label: 'The results expect IO alarm signal output (E.g. the open the door through face recognition)'
            },
            {
              label: 'The results are only displayed on the device, without docking wuth the platfom'
            },
            {
              label: 'Others',
              _labelType: 'slot',
              _inputProp: 'frameworkOthers8'
            }
          ],
          _type: 'checkbox',
          _labelType: 'slot',
          _labelContent: '<span class="dhop-label-content">(Multiple options)</span>',
          _largeCheckbox: true
        },
        {
          label: 'Any other App description',
          prop: 'app_description',
          childList: [],
          _type: 'textarea',
          _largeCheckbox: true
        }
      ],
      dialogVisible: false, // 弹框对话框
      showErrorTip: false,
      isSuccessVerify: false,
      count: '',
      emailDialogVisible: false,
      emailErrorDialogVisible: false,
      emailErrorText: '',
      emailTimer: null
    }
  },
  watch: {
    file(val) {
      if (val) {
        this.showErrorTip = false
      }
    }
  },
  created() {
    this.getCountry()
    // this.handleSubmit()
    this.getUserInfo()
  },
  methods: {
    // 获取用户信息
    async getUserInfo() {
      const res = await getUserInfoApi({ type: 2 })
      if (res.status === '43') {
        this.isLogin = false
      } else if (res.status === '00') {
        this.isLogin = true
        this.ruleFormContact = {
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          position: res.data.position,
          email: res.data.emailAddress,
          phoneNumber: res.data.phoneNumber,
          password: res.data.password,
          pwConfirmation: res.data.password
        }
        this.ruleFormCompany = {
          companyName: res.data.companyName,
          companyUrl: res.data.companyUrl,
          postalCode: res.data.zip,
          city: res.data.city,
          province: res.data.state,
          country: res.data.country,
          companyDescription: res.data.companyDescription,
          employees: res.data.employees,
          businessAreas: res.data.businessAreas,
          companyIndustries: res.data.companyIndustries,
          productsInFocus: res.data.productsInFocus
        }
      }
    },
    // 密码展示或隐藏
    showPwd1() {
      this.pwdType1 = (this.pwdType1 === 'password' ? 'text' : 'password')
    },
    showPwd2() {
      this.pwdType2 = (this.pwdType2 === 'password' ? 'text' : 'password')
    },
    // 文件上传之前
    handleBeforeUpload(file) {
      const fileSize = file.size / 1024 / 1024 < 2
      if (fileSize) {
        this.fileLoading = true
      } else {
        this.$message.error(this.LP.lang_upload_tips)
        return false
      }
    },
    // 上传成功
    handleSuccess(response, file, fileList) {
      if (response.status === '00') {
        this.file = response.data
      }
      this.fileLoading = false
      this.handleRemove()
      console.log(response, file, fileList, 'handleSuccess')
    },
    // 上传失败
    handleError(err, file, fileList) {
      console.log(err, file, fileList, 'handleError')
    },
    // 文件列表移除文件
    handleRemove(file, fileList) {
      // this.fileList = []
    },
    // 国家下拉接口
    getCountry() {
      countrySelectApi().then((res) => {
        this.companyData[3].list = res.data.map(item => {
          return {
            value: item.code,
            label: item.name
          }
        })
      })
    },
    // 省、城市下拉
    getProvince(e, props) {
      if (props === 'country') {
        this.ruleFormCompany.countryCode = this.getCode(e, this.companyData[3].list)
        this.ruleFormCompany.province = ''
        this.ruleFormCompany.city = ''
        provinceCityApi({
          type: 'province',
          code: this.ruleFormCompany.countryCode
        }).then(res => {
          this.companyData[4].list = res.data.map(item => ({
            value: item.code,
            label: item.name
          }))
        })
      } else if (props === 'province') {
        this.ruleFormCompany.provinceCode = this.getCode(e, this.companyData[4].list)
        this.ruleFormCompany.city = ''
        provinceCityApi({
          type: 'city',
          code: this.ruleFormCompany.provinceCode
        }).then(res => {
          this.companyData[5].list = res.data.map(item => ({
            value: item.code,
            label: item.name
          }))
        })
      } else if (props === 'city') {
        this.ruleFormCompany.cityCode = this.getCode(e, this.companyData[5].list)
      }
    },
    getCode(value, data) {
      const arr = data.filter(item => item.label === value)
      const code = arr.length > 0 ? arr[0].value : ''
      return code
    },
    // 提交表单
    handleSubmit() {
      let canSubmit = 0
      this.$refs.ruleFormApp.validate((valid, errs) => {
        if (valid) {
          canSubmit++
        } else {
          const errNums = []
          Object.keys(errs).forEach(item => {
            errNums.push(((this.ruleFormAppErrs[item]).split('/'))[1])
          })
          errNums.sort((a, b) => {
            return a - b
          })
          const isError = document.getElementsByClassName('AI' + errNums[0])
          isError[0].scrollIntoView({
            block: 'center',
            behavior: 'smooth'
          })
        }
      })
      this.$refs.ruleFormCompany.validate((valid, errs) => {
        if (valid) {
          canSubmit++
        } else {
          const errNums = []
          Object.keys(errs).forEach(item => {
            errNums.push(((this.ruleFormCompanyErrs[item]).split('/'))[1])
          })
          errNums.sort((a, b) => {
            return a - b
          })
          const isError = document.getElementsByClassName('PI' + errNums[0])
          isError[0].scrollIntoView({
            block: 'center',
            behavior: 'smooth'
          })
        }
      })
      this.$refs.ruleFormContact.validate((valid, errs) => {
        if (valid) {
          canSubmit++
        } else {
          const errNums = []
          Object.keys(errs).forEach(item => {
            errNums.push(((this.ruleFormContactErrs[item]).split('/'))[1])
          })
          errNums.sort((a, b) => {
            return a - b
          })
          const isError = document.getElementsByClassName('CI' + errNums[0])
          isError[0].scrollIntoView({
            block: 'center',
            behavior: 'smooth'
          })
        }
      })
      if (this.file) {
        this.showErrorTip = false
        canSubmit++
      }
      if (canSubmit === 4) {
        // 判断多选 + Others的情况
        const arr1 = this.ruleFormApp.framework6.map((el, index) => {
          if (el === 'Others') {
            el = el + ':' + this.ruleFormApp.frameworkOthers6
          }
          return el
        })
        const arr2 = this.ruleFormApp.framework8.map((el, index) => {
          if (el === 'Others') {
            el = el + ':' + this.ruleFormApp.frameworkOthers8
          }
          return el
        })
        registerFormApi({
          // Contact Informatiom
          firstName: this.ruleFormContact.firstName,
          lastName: this.ruleFormContact.lastName,
          position: this.ruleFormContact.position,
          email: this.ruleFormContact.email,
          code: this.ruleFormContact.code,
          phoneNumber: this.ruleFormContact.phoneNumber,
          password: this.ruleFormContact.password,
          pwConfirmation: this.ruleFormContact.pwConfirmation,
          // Company Informatiom
          companyName: this.ruleFormCompany.companyName,
          companyUrl: this.ruleFormCompany.companyUrl,
          postalCode: this.ruleFormCompany.postalCode,
          city: this.ruleFormCompany.city,
          province: this.ruleFormCompany.province,
          country: this.ruleFormCompany.country,
          countryCode: this.ruleFormCompany.countryCode,
          provinceCode: this.ruleFormCompany.provinceCode,
          cityCode: this.ruleFormCompany.cityCode,
          companyDescription: this.ruleFormCompany.companyDescription,
          employees: this.ruleFormCompany.employees,
          businessAreas: this.ruleFormCompany.businessAreas,
          companyIndustries: this.ruleFormCompany.companyIndustries,
          productsInFocus: this.ruleFormCompany.productsInFocus,
          dhop: 2, // 1是DEPP 2是DHOP 3是Synergy
          // App Informatiom
          title_one: this.ruleFormApp.framework1 + ':' + this.ruleFormApp.frameworkOthers1,
          title_two: this.ruleFormApp.framework2,
          title_three: this.ruleFormApp.framework3,
          title_four: this.ruleFormApp.framework4,
          title_five: this.ruleFormApp.framework5,
          title_six: arr1,
          title_seven: this.ruleFormApp.framework7,
          title_eight: arr2,
          app_description: this.ruleFormApp.app_description,
          file: this.file
        }).then(res => {
          if (res.status === '00') {
            this.$message.success(res.message)
            if (this.isLogin) {
              this.$router.push(`/`)
            } else {
              this.dialogVisible = true
            }
          } else if (res.status === '43') {
            this.$message.error(res.message)
          } else if (res.status === '41') {
            this.$message.warning(res.message)
            this.$router.push(`/login`)
          }
        })
      } else {
        this.$message.error('Required items can not be empty')
        if (!this.file) {
          this.showErrorTip = true
          if (this.showErrorTip) {
            this.$nextTick(() => {
              const isError = document.getElementsByClassName('error-tip')
              isError[0].scrollIntoView({
                block: 'center',
                behavior: 'smooth'
              })
            })
          }
        }
      }
    },
    // 关闭弹框
    handleClose() {
      this.dialogVisible = false
      const passwordTag = this.encryptedData(this.ruleFormContact.password)
      this.$store.dispatch('login/getLogin', {
        email: this.ruleFormContact.email,
        password: passwordTag,
        verificationCode: '',
        type: 1
      }).then(res => {
        if (res.status === '00') {
          this.$message.success(res.message)
          this.$router.push(`/`)
        }
      })
    },
    // 密码加密处理
    encryptedData(data) {
      var publicKey = `-----BEGIN PUBLIC KEY-----
        MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAyzTUgEniEBho5r+C3/Sz
        +J+cWhKx26zDuD2W9/Dm8mxn1sCK3eEu3SswQ4c/90wIykjBocnw50cQagXG4K8n
        1Fh3Px+1vMSwxzlTY+Sepg3MSFylZ94Fth9bxAAnR4Wtao37BJkWu46rttQiM1PC
        MOKCWS1vdexxKqgTDkgmP2IUUekzyUXBYL5TU5TOrgwfF3lmx3PiLzOoEmDAXru2
        jtjorIlU2vhepJ6k8qB3Qo78gFxwh1ZYaS3vysrm0h/5AnWfN73+xr1fgKA/iT39
        VhRBoi/jacJekOl1Rsg8gEvl4pI33op8U/+7YjZt1nfxq8pNcQjNT3NttBnWmDuE
        BwIDAQAB
        -----END PUBLIC KEY-----`
      var encrypt = new JSEncrypt()
      encrypt.setPublicKey(publicKey) // 设置公钥
      const encrypted = encrypt.encrypt(data) // 密码加密
      return encrypted
    },
    openVerifyPopover() {
      const regEmail = /^[\w]+([-_.][\w]+)*@([-_\w]+\.)+[a-z]{2,14}$/i
      if (regEmail.test(this.ruleFormContact.email)) {
        this.emailDialogVisible = true
      } else {
        this.$refs.ruleFormContact.validateField('email')
        this.emailDialogVisible = false
      }
    },
    // 邮箱验证成功
    emailSuceess() {
      emailVerifyApi({
        type: 'register',
        email: this.ruleFormContact.email
      }).then(res => {
        if (res.status === '43') {
          this.emailDialogVisible = false
          this.emailErrorText = res.data
          this.emailErrorDialogVisible = true
        } else {
          this.emailDialogVisible = false
          this.emailErrorText = this.LP.lang_verification_successful_email
          this.emailErrorDialogVisible = true
          if (this.emailErrorDialogVisible) {
            setTimeout(() => {
              this.emailErrorDialogVisible = false
              this.isSuccessVerify = true
              // 倒计时
              const TIME_COUNT = 180
              if (!this.emailTimer) {
                this.count = TIME_COUNT
                this.emailTimer = setInterval(() => {
                  if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count--
                  } else {
                    clearInterval(this.emailTimer)
                    this.emailTimer = null
                    this.isSuccessVerify = false
                  }
                }, 1000)
              }
            }, 3000)
          }
        }
      }).catch(error => {
        this.emailDialogVisible = false
        if (error.response && error.response.status === 401) {
          this.emailErrorText = error.response.data.data
          this.emailErrorDialogVisible = true
        }
      })
    },
    // 关闭验证弹框
    closeDialog() {
      this.emailDialogVisible = false
    },
    closeEmailError() {
      this.emailErrorDialogVisible = false
    },
    // 登录后账号升级提交
    accountUpgradeSubmit() {
      let canSubmit = 0
      this.$refs.ruleFormApp.validate((valid, errs) => {
        if (valid) {
          canSubmit++
          if (this.file) {
            this.showErrorTip = false
            canSubmit++
          }
          if (canSubmit === 2) {
            // 判断多选 + Others的情况
            const arr1 = this.ruleFormApp.framework6.map((el, index) => {
              if (el === 'Others') {
                el = el + ':' + this.ruleFormApp.frameworkOthers6
              }
              return el
            })
            const arr2 = this.ruleFormApp.framework8.map((el, index) => {
              if (el === 'Others') {
                el = el + ':' + this.ruleFormApp.frameworkOthers8
              }
              return el
            })
            accountUpgradeApi({
              dhop: 2,
              email: this.ruleFormContact.email,
              title_one: this.ruleFormApp.framework1 + ':' + this.ruleFormApp.frameworkOthers1,
              title_two: this.ruleFormApp.framework2,
              title_three: this.ruleFormApp.framework3,
              title_four: this.ruleFormApp.framework4,
              title_five: this.ruleFormApp.framework5,
              title_six: arr1,
              title_seven: this.ruleFormApp.framework7,
              title_eight: arr2,
              app_description: this.ruleFormApp.app_description,
              file: this.file
            }).then(res => {
              if (res.status === '00') {
                this.$message.success(res.message)
                this.$router.push(`/`)
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('Required items can not be empty')
            if (!this.file) {
              this.showErrorTip = true
              if (this.showErrorTip) {
                this.$nextTick(() => {
                  const isError = document.getElementsByClassName('error-tip')
                  isError[0].scrollIntoView({
                    block: 'center',
                    behavior: 'smooth'
                  })
                })
              }
            }
          }
        } else {
          const errNums = []
          Object.keys(errs).forEach(item => {
            errNums.push(((this.ruleFormAppErrs[item]).split('/'))[1])
          })
          errNums.sort((a, b) => {
            return a - b
          })
          const isError = document.getElementsByClassName('AI' + errNums[0])
          isError[0].scrollIntoView({
            block: 'center',
            behavior: 'smooth'
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/pages/registerForm.less';
.el-input__suffix-inner {
  cursor: pointer;
}
.dhop-wrap {
  .dhop-tip-box {
    background: #F5F7FA;
    border-radius: 1px;
    margin-top: 24px;
    padding: 22px 16px;
    text-align: center;
    .tip-text {
      font-size: 16px;
      font-weight: 600;
      line-height: 21px;
      margin-bottom: 10px;
      display: block;
      & + .tip-text {
        margin-bottom: 0;
      }
      &.agreement {
        font-weight: 400;
        .link {
          color: #0E5FAC;
          text-decoration: underline;
        }
      }
    }
    .input-box {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      /deep/ .el-input {
        max-width: 440px;
        margin-right: 24px;
      }
      .gray-btn {
        background: #909399;
        border-color: #909399;
      }
      .dhop-upload {
        display: flex;
        flex-direction: row-reverse;
        .gray-btn {
          margin-left: 24px;
          font-size: 16px;
          font-weight: bold;
          line-height: 20px;
          padding: 9px 20px;
        }
      }
    }
    .error-tip {
      color: #F56C6C;
      font-size: 12px;
      margin-top: 4px;
    }
  }
  .app-infomation {
    .el-radio-group,
    .el-checkbox-group {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .el-radio,
      .el-checkbox {
        width: calc(25% - 23px);
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 21px;
        &:last-child {
          margin-right: 30px;
        }
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
    .large-checkbox {
      .el-checkbox {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    /deep/ .dhop-label-content {
      font-weight: 400;
    }
    .label-input {
      width: 160px;
      margin-left: 8px;
      /deep/ .el-input__inner {
        border: none;
        border-bottom: 1px solid #E4E7ED;
      }
    }
  }
  /deep/ .el-dialog {
    width: 40%;
    margin-top: 15vh;
    .el-dialog__body {
      padding: 25px 25px 30px;
      text-align: initial;
      .content {
        padding: 0 40px 20px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
	@media screen and (max-width:1024px){
		.app-infomation {
			.el-radio-group,
			.el-checkbox-group {
				flex-direction: column;
			}
		}
	}
	@media screen and (max-width:768px){
		.dhop-tip-box{
			.input-box{
				.dhop-upload{
					flex-direction: column-reverse;
					.gray-btn{
						margin: 15px 0 0 0;
					}
				}
			}
		}
		.app-infomation{
			.large-checkbox{
				.el-checkbox{
					white-space: break-spaces;
					display: flex;
					position: relative;
					/deep/.el-checkbox__input{
						position: absolute;
						top: 4px;
					}
					/deep/.el-checkbox__label{
						padding-left: 24px;
					}
				}
			}
			.label-input{
				/deep/.el-input__inner{
					height: 22px;
				}
			}
		}
	}
}
.verify-btn,
.email-time-btn {
  padding: 5px 16px;
  font-size: 14px;
  line-height: 18px;
  border: 1px solid @theme;
}
.verify-btn {
  border-radius: 4px;
  color: #FFFFFF;
  background: @theme;
}
.email-time-btn {
  color: @theme;
  background: transparent;
}
/deep/ .email-input{
  .el-input__inner {
    padding-right: 90px;
  }
}
</style>

<style lang="less">
.dhop-wrap {
  // 上传文件样式重置
  .el-upload-list {
    min-width: 400px;
    height: 40px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #E4E7ED;
  }
  .el-upload-list__item:hover {
    background-color: transparent;
  }
  .el-upload-list__item {
    line-height: 40px;
    text-align: left;
    padding: 0 16px;
  }
  .el-upload-list__item:first-child {
    margin-top: 0;
  }
  .el-upload-list__item .el-icon-close {
    top: 14px;
  }
  .login-hidden {
    display: none;
  }
  /deep/.el-checkbox.is-disabled {
    background-color: #F5F7FA;
    border-color: #E4E7ED;
    color: #C0C4CC;
    .public-check-box {
      cursor: not-allowed;
    }
  }
	@media screen and (max-width: 768px) {
		.el-upload-list{
			min-width: 310px;
		}
	}
}
</style>

import { _axios } from '@/plugins/axios.js'

const apiUrl = {
  register: '/user/register_page', // 注册页
  registerForm: '/user/register', // 注册表单提交
  dhopQuestion: '/user/dhop_question', // DHOP注册页的8道题接口
  upload: '/home/upload', // 上传文件接口
  provinceCity: '/user/province_city', // 省和城市接口
  accountUpgrade: '/user/account_upgrade' // 登录后账号升级
}

// 注册页
export const registerApi = () => _axios.get(apiUrl.register)
// // 注册表单提交
export const registerFormApi = (params) => _axios.post(apiUrl.registerForm, params)
// DHOP注册页的8道题接口
export const dhopQuestionApi = () => _axios.get(apiUrl.dhopQuestion)
// 上传文件接口
export const uploadApi = (params) => _axios.post(apiUrl.upload)
// 省和城市接口
export const provinceCityApi = (params) => _axios.post(apiUrl.provinceCity, params)
// 登录后账号升级
export const accountUpgradeApi = (params) => _axios.post(apiUrl.accountUpgrade, params)
